<template>
  <div id="package-expired-page" class="container is-max-desktop">
    <div class="columns is-mobile">
      <div class="column">
        <img src="../../assets/images/expired-package.svg" width="600" />
      </div>
      <div class="column">
        <div class="text_content">
          <h1 class="header_text">Package expired.</h1>
          <p class="text_body expired-text">
            {{
              isAdminOrCompanyOwner
                ? 'Sorry, your company package has expired, please buy new package.'
                : 'Your company package has expired, please contact your admininstrator.'
            }}
          </p>
        </div>
        <template v-if="isAdminOrCompanyOwner">
          <button class="buy-package-button">
            <a href="/subscription/package/choose">Buy Package</a>
          </button>
          <a href="/subscription" class="choose-package-link">
            go to subscription page
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  data() {
    return {
      user: {},
    }
  },
  methods: {
    async getUser() {
      try {
        this.user = store.getters['user/getUserInfo']
      } catch (error) {
        this.user = null
      }
    },
    goToSubscription() {
      this.$router.push('/subscription')
    },
  },
  mounted() {
    this.getUser()
  },
  computed: {
    isAdminOrCompanyOwner() {
      return this.user.isAdmin || this.user.isCompanyOwner
    },
  },
}
</script>
